
body {
    font-family: 'Roboto', sans-serif;
}

.navbar-nav {}

.MuiDialog-paperFullWidth {
    padding: 3% !important;
}

.active img{
    cursor: pointer;
}

.navegacion i{
    color: rgb(255, 255, 255);
        margin: 30px;
        font-size: 30px;
    }
    
    .navegacion{
        position: absolute;
        bottom: 20px;
      left: 50%;
      transform: translate(-50%, -50%);
        opacity: .5;
    
    }
    .navegacion:hover{
        display: none;
    }

.modal {
    z-index: 2247483648 !important;
}

.dropdown-menu.show {
    display: block;
    width: 100%;
}

.formAdmin input, .formAdmin select{
    background-color: transparent;
    border: none;
    border-radius: 0;
    border-bottom:  2px solid #edd77c;
    padding: 5px;
    margin-bottom: 10px;    
}
 .activo a{
text-decoration: underline solid 2px;
text-decoration-color:  #3064a6 !important;
border-bottom-style: solid ;
border-bottom-width: 3px;
border-bottom-color: #3064a6
}

.activoU a{
    text-decoration: underline solid 2px;
    text-decoration-color:  #edd77c !important;
    border-bottom-style: solid ;
    border-bottom-width: 3px;
    border-bottom-color: #edd77c
    }

.formAdmin input::placeholder, .formAdmin select {
    font-size: 13px;
    color: rgb(202, 202, 202);
    padding-left: 20px;
    letter-spacing: 0.6px;  
}

.form-check-input:checked {
    background-color: #ffc107;
    border-color: #ffc107;
}

.form-switch label{
    color: #77adf2;
    font-size: 14px !important;
}

#select select{
    color: black;
}

.progreso progress{
    width: 100%;
    height: 25px;
}

.progreso input[type="file"] {
    display: none;
}
.custom-file-upload {
    border: 2px solid #edd77c;
    border-radius: 100px;
    display: inline-block;
    padding: 5px 15px;
    cursor: pointer;
    background: lightgray;
}

.MuiTableHead-root th{
    background-color: #3064a6;
}

.instruction{
    font-size: 13px;
     color: rgb(168, 164, 164);
  
}

.gray {
    background-image: url(img/header2.png);
    background-position: center;
    background-color: black !important;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    padding-top: 0px;
    position: relative;
    height: 100vh;
}

.imagen-2 p {
    background-color: black;
    color: white;
    padding: 20px;
}

.white {
    background-color: white;
    padding-top: 50px;
    padding-bottom: 50px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
}

.input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    margin-right: 0 !important;
}

.input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-left: -1px !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    margin-right: 0 !important;
}

.white2 {
    background-color: white;
    padding-top: 50px;
    padding-bottom: 50px;
}

.white p {
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    /* font-weight: 100; */
}

.btn-default {
    background-color: black;
    color: white;
    padding: 10px 40px;
    margin-top: 10px;
}

.black {
    background-color: rgb(0, 0, 0, 0.54);
    background-size: contain;
    background-repeat: no-repeat;
    padding-top: 50px;
    padding-bottom: 50px;
}

.black2 {
    background-color: black;
    padding-top: 50px;
    padding-bottom: 50px;
    color: white;
    font-family: 'roboto';
    font-weight: 300;
    font-size: 20px;
}

.black img {
    max-width: 200px;
    width: 100%;
}

.black h2 {
    color: white;
    font-size: 30px;
    font-weight: 100;
    text-align: center;
    margin-top: 20px;
}

.black a {
    text-decoration: none !important;
}

.black h2:hover {
    color: #ffce00;
}

.black img:hover {
    padding: 5px;
    transition-duration: .4s;
}

.black p {
    color: white;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-weight: 100;
}

.black h3 {
    color: white;
    font-family: 'Roboto', sans-serif;
    font-size: 26px;
    font-weight: 400;
    text-align: center;
}

form {
    width: 100%;
}

select {
    width: 100%;
}

textarea {
    width: 100%;
}

footer {
    background-color: #edd77c;
    padding-top: 50px;
    padding-bottom: 50px;
}

footer a {
    color: white;
}

.navbar-center {
    margin: 0 0 0 25%;
}

.arrow {
    padding-top: 150px;
    padding-bottom: 150px;
}

.flotantes li {
    margin-bottom: 2px;
    list-style: none;
}

.flotantes ul li {
    list-style: none;
}

.flotantes {
    padding: 0px;
    position: fixed;
    right: 0;
    top: 30%;
}

.flotantes img {
    max-width: 60px;
    background-color: black;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.flotantes img:hover {
    transition-duration: .4s;
    background-color: #ffce00;
}

.navbar-brand img {
    max-width: 150px;
}
.soon{
    color: white;
    letter-spacing: 20px;
    font-weight: 500;
}
.header img {
    max-width: 420px;
    width: 100%;
}

.header {
    padding-bottom: 120px;
    padding-top: 120px;
    background-color: #00000047;
    height: 100vh;
}

.header h2 {
    font-family: "Helvetica";
    font-weight: lighter;
    color: white;
    padding-top: 20px;
    font-size: 35px;
}

.head {
    position: relative;
}

.panoramica {
    display: block;
    background-color: black;
    cursor: move;
    position: relative;
}

.Titulo h2 {
    font-family: "Helvetica";
    font-weight: 800;
    font-size: 28px;
}

.Titulo hr {
    width: 30%;
    height: 6px;
    border-radius: 20px;
    background-color: #ffce00;
}

.Titulo {
    padding-top: 20px;
    padding-bottom: 60px;
}

.Titulo img {
    max-width: 300px;
}

.recomendaciones {
    margin-top: 100px;
    margin-bottom: 100px;
}

.recomendaciones .opcion {
    padding-bottom: 30px;
}

.navbar-dark {
    background-color: transparent;
}

.bg-dark2{
    background-color: black;
}
.bg-blue2{
    background-color: #3064a6;
}

.nav-link img {
    max-width: 60px;
}

.card .btn-warning {
    font-size: 14px;
    padding: 5px 40px;
    border-radius: 50px;
    font-weight: 800;
    background-color: #edd77c;
}

.btn-warning {
    padding: 5px 40px;
    border-radius: 50px;
    font-weight: 800;
    background-color: #edd77c;
}

.recomendaciones {
    padding-top: 0px;
    padding-bottom: 0px;
}

.filtro {
    margin-top: 0px;
    background-color: #3064a6;
    padding: 20px;
    border-radius: 10px;
}

.dos1 {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    align-content: center;
    width: 100% !important;
}

 .swal2-container{
    z-index: 3000 !important;
}

.form-middle {
    padding: 0 !important;
    width: 90px !important;
}

.position-sticky {
    padding-top: 60px;
}

.filtro h4 {
    color: white;
}

.filtro i {
    color: white;
    font-size: 25px;
}

.filtro i:hover {
    color: #77adf2;
    transition-duration: .4s;
}

.filtro i:active {
    color: red;
}

.card i {
    color: #e9c681;
    font-size: 20px;
    padding: 0 10px;
}

.filtro .form-label {
    color: white;
    font-size: 18px;
}

.filtro option {
    color: #77adf2;
    margin-bottom: 3px !important;
}
.filtro option:checked  {
    border-bottom: 1px solid #edd77c !important;   
    padding-bottom: 3px !important;
    background-color: transparent !important;
    color: #edd77c !important;
}


.card {
    /* border: solid #e9c681 2px; */
    margin: 20px 20px;
    padding: 0;
    width: 18rem;
    max-width: 100%;
}

.card:hover {
    transform: scale(1.02);
    transition-duration: 0.4s;
}

.card hr {
    width: 100%;
    border: 5px solid #edd77c;
    margin: 0;
    padding: 0;
}

.btn-light {
    width: 100%;
}

.filtro .iconos {
    margin-top: 30px;
    margin-bottom: 30px;
}

.filtro a:hover {
    color: bisque;
}

.filtro a {
    color: white;
}

.card-body .decoracion {
    height: 6px;
    background-color: #edd77c;
    width: 100%;
    border-radius: 50px;
}

.decoracion {
    height: 6px;
    background-color: #edd77c;
    width: 100%;
    border-radius: 50px;
}

.card-text {
    padding-top: 25px;
    padding-bottom: 25px;
}

.separador {
    width: 100%;
    background-color: #edd77c;
    height: 8px;
}

.margin {
    margin-top: 20px;
    margin-bottom: 20px;
}

.nav-tabs {
    margin-bottom: 20px;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: white;
    background-color: #77adf2;
    border-color: #77adf2;
}

/* .form-select {
    display: block;
    width: 100%;
    padding: .375rem 2.25rem .375rem .75rem;
    -moz-padding-start: calc(0.75rem - 3px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e);
    background-repeat: no-repeat;
    background-position: right .75rem center;
    background-size: 16px 12px;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
} */

.input-group {
    padding: 5px;
}

.right2 {
    margin-left: 60% !important;
}

.btn-primary {
    width: 100%;
}

.mobile-filtro {
    margin-top: 20px;
    margin-bottom: 0px;
}

.btn-info {
    width: 100%;
}

.btn-outline-primary {
    width: 100%;
}
.whitet{
	color: white;
}

.filtro-mapa {
    background-color: #3064a6;
    padding-top: 30px;
    padding-bottom: 30px;
    position: fixed;
    top: 55px;
    width: 100%;
    z-index: 6;
}

.top {
    margin-top: 65px !important;
}

.filtro-mapa .btn-light {
    font-weight: 400;
    border: solid #77adf2 2px;
    margin-top: 5px;
    margin-right: 10px;
    width: 90%;
    display: block;
    font-size: 14px;
}

.filtro-mapa .form-select {
    background-image: url(img/chedron.svg);
}

.filtro-mapa select {
    background-color: #3064a6;
    color: white;
    font-weight: 200;
    border: solid #77adf2 2px;
    margin-top: 5px;
    width: 95%;
    display: block;
    font-size: 14px;
}

.filtro-mapa input {
    background-color: white;
    color: gray;
    font-size: 14px;
    font-weight: 300;
    border: solid #77adf2 2px;
    height: 35px;
    width: 100%;
    padding-left: 5px;
}

.buscar {
    width: 80%;
    background-color: white;
    font-size: 14px;
    font-weight: 300;
    border: solid #77adf2 2px;
}

.mapa-card {
    position: absolute;
    right: 0;
    background-color: white;
    top: 193px;
    overflow: scroll;
    height: 800px;
    z-index: 4;
}

.mapa {
    position: absolute;
}

.form select, .form input {
  
    margin-right: 0;
   }
.dropdown{
    min-width: 170px;
}

/*! media queries  inicio*/
@media (max-width: 900px) {
    .aside {
        display: none;
    }
    .soon{
        color: white;
        letter-spacing: 10px;
        font-weight: 500;
    }
    .flotantes {
        display: none;
    }
    .mobile{
        display: none;
    }

    .d-flex{
        margin-top: 80px;
        flex-direction: column !important;
    }

    .d-flex button{
        margin-bottom: 20px;
    }
    .card {
        /* border: solid #e9c681 2px; */
        margin: 20px 20px;
        margin-right: 0!important;
        margin-left: 0!important;
        padding: 0;
        width: 100%;
        
    }
    .row{
        /* margin-right: calc(var(--bs-gutter-x) * -.5); 
     margin-left: calc(var(--bs-gutter-x) * -.5); */
     margin-left: 0 !important;
     margin-right: 0 !important;
     width: 100%;
    }
    .margin {
        padding: 0px !important;
    }
    .container{
        padding-right: 0 !important; 
    padding-left:0 !important;
    }
    
}

@media (max-width: 575.98px) {
    .filtro {
        margin-top: 0;
    }

    .form select, .form input {
     width: 80%;
     margin-right: 0;
    }
    .formButton button {
        width: 80%;
    }
    .form img {
         width: 100%;
      
    }

    .buttonOk {
    margin: 10px;
    font-size: 16px;
        
    }

    .buttonBack {
        margin: 10px;
        font-size: 16px;
    }

  


    .form label {
        font-size: 18px;
        padding: 0 0 20px;
    }

    .Titulo img {
        max-width: 200px;
    }
    .mapa-card {
        position: relative;
        top: 70px;
    }
    .filtro-mapa {
        position: relative;
    }
    .filtro-mapa select {
        margin: 10px 0;
    }
    .mapa {
        position: relative;
    }
    .flotantes img {
        max-width: 40px;
        background-color: black;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }
    .gray {
        background-attachment: scroll;
    }
    .header img {
        max-width: 220px;
        width: 100%;
    }
    .header {
        padding-bottom: 150px;
        padding-top: 150px;
    }
    .header h2 {
        font-size: 25px;
    }
    .black .icon {
        max-width: 120px;
        width: 100%;
    }
    .recomendaciones {
        margin-top: 0;
        margin-bottom: 0;
    }
    .black {
        background-color: black;
    }
}

@media (min-width: 768px) {
    .navbar-left {
        float: left !important;
    }
    .navbar-right {
        float: right !important;
        margin-right: 10%;
        background-color: red;
    }
    .navbar-right~.navbar-right {
        margin-right: 0;
    }
}

@media (min-width: 1920px) {
    .header {
        padding-bottom: 10%;
        padding-top: 15%;
    }
}

@media (min-width: 768px) {
    .navbar-right .dropdown-menu {
        right: 0;
        left: auto;
    }
    .navbar-right .dropdown-menu-left {
        right: auto;
        left: 0;
    }
    
}

.subtitulo-descripcion table tr{
    margin-bottom: 0px !important;
    vertical-align: top !important ;
}

.subtitulo-descripcion table td{
    margin-bottom: 0px !important;
}

.subtitulo-descripcion h6{
    margin: 2px !important;
    margin-block-start: 4px !important;
    margin-block-end: 4px !important;
}

.lines{
    height: 5px;
}


/*! media queries  final*/

.titulo-descripcion {
    margin-top: 25px;
    margin-bottom: 25px;
}

.subtitulo-descripcion {
    margin-top: 25px;
    margin-bottom: 25px;
}

.mapa-descripcion {
    margin-top: 25px;
    margin-bottom: 25px;
}

.icono-descripcion img {
    max-height: 40px;
    margin-bottom: 10px;
    margin-top: 10px;
}

.asesor {
    background-color: #d8d8d8;
    padding: 20px;
    margin-top: 10px;
    border-radius: 10px;
}

.form-check {
    font-size: 13px;
    font-weight: 400;
}

.accordion-button{
    background-color: white !important;
    color: gray !important;
}
.aside {
    background-color: #e5e5e5;
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 20px;
    height: 100vh;
    margin-left: -10px;
    font-size: 18px;
}

.aside ul{
 list-style-type: none;
    padding: 10px;
     
}

.aside li{  
       margin-bottom: 10px; 
       box-shadow: 1px 1px 1px 1px rgb(195, 195, 195);
       padding: 5px;
       padding-left: 15px;
       margin-left: 15px;
   }

.aside a{
    text-decoration: none;
    color: black;
    line-height: 2.5;     
}

.aside a:hover{
    color: #3064a6;    
}

.aside h3{
    margin-top: 30px;
    margin-bottom: 50px;
    text-align: center;
}