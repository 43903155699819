// .black{
//     background: black;  
//     z-index: 9999999999999;  
// }
.center{    
    width: 70%;
}

.favBut{
    background: none !important;
    border: none !important;
}

.carousel-item {
    display: block;
}
.carousel-image{
    
    cursor: pointer;
    
}
.form-select[multiple]{
    height: 140px;
    overflow: hidden;
    background-color: transparent !important;
    border: none !important;
    margin-bottom: 3px !important;
}
.form-select{
    font-size: 14px !important;
}
.fade:not(.show) {
    opacity: 1;
}
[data-reach-combobox-popover]{
    z-index: 999999;
}
.topSpace{    
    margin-top: 50px !important;   
}
.logBut{
    background: transparent;
    color: white;
    border:none !important;
    font-weight: bold;
}
.flexLogin{
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
}
.bottomWidth{
    width: 100%;
}
.loginForm{   
    // background: black;
    height: 100vh;
    
    margin: auto 0;
    background-color: black;
    input{
        background: white !important;
        border-radius: 5px !important;
        height: 40px !important;
       
    }
    .login{
        margin-top: 15%;
        color: white;
        span{
            display: contents;
        }        
    }
}
.nav-link {
    color: white !important;
}

.loginForm2{   
    // background: black;
    height: 100vh;    
    margin: auto 0;
    background-color: white;
    input{
        background: white !important;
        // border-radius: 5px !important;
        border:none;
        border-bottom: 1.5px solid rgb(235, 202, 68) !important;
        height: 40px !important;
       
    }
    .login{
        margin-top: 15%;
        color: white;
        span{
            display: contents;
        }        
    }
}

.topSpace2{
    margin-top: 120px;
}
.center{
   
        text-align: center !important;
        margin: 0 auto !important;
    
}
.bg-dark {
    //  --bs-bg-opacity: 0; 
    background-color: black !important;
}

.boxMap{
    padding: 15px;
    background: rgb(241, 241, 241);
    height: 80px;
  
}

.white{
    color: white !important;
    font-weight: normal;
    font-size: 34px;
}

.iconsNavbar{
    color: white !important;
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-content: stretch;
    justify-content: space-around;
    img{      
        height: 50px;
    }
}

html{
    font-family: Helvetica, Sans-Serif; 
    margin: 0 auto; 
    height: 100%;       
}
.editarOficina{
    display: flex;
   margin: 1px;
   p{
margin-top: 5px;
margin-bottom: 0 !important;
   }
    .form-control{
width: 25%;
height: 25px;
    }
}
.swal2-styled.swal2-confirm{
    background-color: #edd77c !important;
    color: black !important;
    font-size: 18px;
    font-weight: bold;
}

.colorHead{
    // color: #edd77c !important;
    color: #ebca44 !important;
    
    font-weight: bold;
}

.flex{
    text-align: center;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;  
    margin-right: 50px;
    margin-left: 50px;
}
.flexGrid{
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    height: 70vh;
    margin-left: 156px;
    width: 100%;
    padding-right:20px ;
    // z-index: 9999999999;
    background: white;
   
}
.iconNav{
    width: 15px;
    margin-right: 10px;
   
}
.iconNav2{
    width: 15px;
    margin-right: 10px;
    margin-top: -5px;
}
.iconNav3{
    width: 25px;
    margin-right: 10px;
    margin-top: -5px;
}
.iconNav4{
    width: 20px;
    margin-right: 10px;
    margin-top: -5px;
}
.normal{
    font-weight: normal;
}

.form-create{
    input{
        margin: 10px;
        border-radius: 100px;
    }
}

// .form:after{    
//   border-left: 10px solid transparent;
//   border-right: 10px solid transparent;
//   border-top: 10px solid #99cc00;
//   position: absolute;
//   margin-left: 11%;
//   margin-top: -2%;
//   content: "";
//   z-index: 98;
// }
// a, a:hover{
//     color: black;
//     text-decoration: none;
// }
// a{
//     display: flex;
//     justify-content: center;
// }
.loginForm{           
         input{
            padding-left: 20px !important;
            // width: 40%;
            border-radius: 100px !important;
            padding: 5px;
            background: gainsboro;
            border: none;   
    
        } 
}
.form{
    margin-top:3% 3% 3%;
    text-align: center;
    
    img{
        width: 15%;  
        height: 100px;  
        margin: 50px 0;    
    }
    label{
        font-size: 28px;
        font-weight: bold;
        padding: 0 0 30px;
        
    }
   
    
   
  

    select, input{
        padding-left: 20px !important;
        width: 25%;
        border-radius: 20px;
        padding: 10px;
        background: gainsboro;
        border: none;   
        font-size: 18px;
    } 
    select{
        background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgba(0, 0, 0, 1)'><polygon points='0,0 100,0 50,50'/></svg>") no-repeat;
        background-size: 12px;
        background-color: gainsboro;
        background-repeat: no-repeat, repeat;
        background-position: right .7em top 50%, 0 0;
        border-color: #EDD77C;
        height: 45px;
    }
    .junt{
        margin-left: 30%;
        width: 40%;
        text-align: center;
        
        input[type="radio"] {
            display: none;
          }
           input[type="radio"] + *::before {
            content: "";
            display: inline-block;
            vertical-align: bottom;
            width: 2rem;
            height: 2rem;
            margin-right: 1rem;
            border-radius: 50%;
            border-style: solid;
            border-width: 0.1rem;
            border-color: gray;
          }
         input[type="radio"]:checked + * {
            color: #EDD77C  ;
          }
         input[type="radio"]:checked + *::before {
            background: #EDD77C ;
            border-color: #EDD77C ;
          }
          
        
           input[type="radio"] + * {
            display: inline-block;          
           
          }
          
    }
}
.formButton{
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
button{
    width: 25%;
}
}
.navbar-brand{
    text-align: center;
    margin-right: 0;
    width: 30% !important;    
    .img{
        width: 70%;
    }
}
// .card{
//     a{
//         display: flex;
//         flex-direction: column;
//     }
// }

.iconHome{   
    img{
        width: 150px;
    }
    h2{
        margin-top: 20px;
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 20%;
    }
}

.full{  
    .videoSpace{
        opacity: 0.6;
    }
}


.full:hover{     
     img { 
        background: #EDD77C ;
        border-radius: 50%;
    }
    .videoSpace{
        opacity: 1;
    }
}

.home{
    margin-top: 4%;
    text-align: center;   
    min-height: 600px;
}

.underline{    
    background-image: linear-gradient(transparent 24px , #93b343 50%, #c9d753 100%);    
}

.videoSpace{
    margin-top: 30px;
    margin-bottom: 30px;
    width: 100%;
}

figcaption {
	margin-top: 10px;
	font-size: 12px;
	/* display:none;  */
	transition: all .5s;
	text-align: center;
	visibility: hidden;
    color:white !important;
    font-weight: bold;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear, width 0.8s;	
  }
  /* Visible texto */
  figure:hover > figcaption {
	display: block !important;
	transition: all .5s;
	visibility: visible;
    opacity: 1;
	animation: fadeInLeft; 
	animation-duration: 1s;
    background: linear-gradient(transparent 17px , #93b343 0%, #c9d753 100%) ;
  }

.wspIcon{
    position: fixed;
    right: 0%;
    top: 80%;   
    margin: 0;
    z-index: 99999;
	width: 80px !important;
    list-style-type: none;      
	border: solid #99cc00;   
    border-radius: 0.5em 0em 0em 0.5em;
    text-align: center;
    background: white;
    img{
        width: 40px;        
        padding: 5px;
        margin-left: -25px;
    }
}


// footer{
//     background: black;
//     color: white;
//     display: flex;   
//     height: 50px;
//     flex-wrap: nowrap;
//     align-content: stretch;
//     justify-content: space-around;   
// 	bottom: 0;
//     margin-top: auto;
//     width: 100%;
//     bottom: 0;
//     position: absolute;
   
//     p{
//     margin-top: 15px;
//     }
// }


* {
    padding: 0px;
    margin: 0px;
    }
   
    .content, body {
    min-height: 100%;
    position: relative;
    width: 100%;
    }

.mapModal{
    background-color: white;
    text-align: center;
    
    img{
        width: 60px;
        margin-bottom: 10%;
    }
    h1{
        font-weight: bold;
        font-size: 28px;
        margin-bottom: 10%;
    }
    input{
        margin-left: 40%;
        border: none;
        height: 36px;
        width: 40%;
        font-size: 18px;
        padding-left: 20px;
    }
    .buttomMap{
        color: black;
        font-weight: bold;
        background: linear-gradient(to right, #93b343 0%, #c9d753 100%) !important  ;
        border:none;
        border-radius: 0px 20px 20px 0px;
        width: 100px;
        height: 50px;
        img{
            width: 20% !important;
        }
    }
}
.buttomMap{
    color: black;
    font-weight: bold;
    background-color: #c9d753 !important;
    border:none;
    border-radius: 0px 20px 20px 0px;
    width: 100px;
    height: 50px;
    margin-right: 15px;
    img{
        width: 50% !important;
    }
}
.searchMap{
    margin-top: 30px;
}
.dos{
    width: 100%;
    display: flex;
    input{
        height: 50px;
        width: 100%;
    }    
}
.dos .search{
    // width: 100%;
    input{
        height: 50px;
        width: 500px;
    }
}
.modal-content{
    min-height: 200px;
    border: none;    
}

.pdf{
    margin-top: 100px;
}
.galleryModal{
    margin-top: 80px;
}
.iconosOficina {
    margin: 0 auto;
    text-align: center;
    img{
        margin: 0 10px 10px 0 ;
    width: 35px !important;
    }
}

.iconosOficinaEstilo{
    margin: 0 auto;
    text-align: center;
    img{
        margin: 0 10px 10px 0 ;
    width: 28px !important;
    }
}
.gridMap{
    // margin: 50px;
    display: flex;
    // width: 90%;
}
.flexMap{    
    display: flex;
    flex-direction: column;    
    align-items: center;
    // width: 50%;
    img{
        margin-bottom: 20px;
    }
    button{
        width: 50%;
        font-weight: bold;  
        background: rgb(241, 241, 241); 
        border: none;  
        border-radius: 24px;
        height: 50px; 
    }
}
ul{
    margin-block-start: 0px !important;
    margin-block-end: 0px !important;
    margin-inline-start: 0px !important;
    margin-inline-end: 0px !important;
    padding-inline-start: 0px !important;
    padding:0px !important;
}
.dot{
    display: none !important;
}
.carousel-root{
    display: flex;
}
.carousel{
  
    ul{
        display: grid;
        grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    justify-items: stretch;
    align-items: stretch;
 
    }
    li{
        width: 100% !important;        
    }
    .thumbs{
        display: grid;
    }
}
.active, .dot:hover {
    background: transparent !important;
}
// .carouselGral{
//     display: flex;
// }
.box{
    display: flex;
    justify-content: space-between;
    align-content: stretch;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: nowrap;
}
.active-thumbnail{
    border: #EDD77C solid 3px !important;
}
.thumbnails{
    display: grid !important;
    grid-template-columns: 50% 50%;
    grid-template-rows: 29.5% 29.5% 29.5%;
    grid-column-gap: 10px;
    grid-row-gap: 4%;
    justify-items: stretch;
    align-items: stretch !important;
    padding-left: 10px;
    margin-top: 0px !important;
    img{
        // height: 100%;  
        cursor: pointer;
        width: 252px;    
    }
    
}
.carousel-image{
    width: 113% !important;
}
.carousel-control-next, .carousel-control-prev{
    margin-top: 10% !important;
}
// #root > div > div > div > div > div > div:nth-child(1) > div > div > a.prev
.carouselGral{
    a.next, a.prev{
        margin-top: 25% !important;
 
    }
}

.prev, .next{
    // margin-top: 25% !important;
    color: #0d6efd;
    text-decoration: none !important;
    
}

.next{
    right: -13% !important;
}

a.next:hover{
    background-color: transparent;
    color: #EDD77C;
}

a.prev:hover{
    background-color: transparent;
    color: #EDD77C;
}

.infoGral{
    display: flex;    
    align-content: center;;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;     
    position: relative;
  
}
.tableBuild{
    position: relative;
    display: block;
    margin-left: -61%;
    margin-top: 100px;
    margin-bottom: 50px;
}
.tableBuild2{
    position: relative;
    display: block;
    margin-left: -54%;
    margin-top: 100px;
    margin-bottom: 50px;
}
.infoBuild{
    border: 2px solid #99cc00;
    // width: 68%;
    padding: 20px;
    margin-bottom: 20px;
    width:72%;
    h1{
        text-align: left;
    }
    td{
        padding-right: 10px;
    }
}
.build{   
    width: 100% 
}
   
.infoBuildWithout{   
    width: 68%;
    padding: 20px;
    margin-bottom: 20px;
    h1{
        text-align: left;
    }
    p{
        margin-bottom: 2px;
    }
    td{
        padding-right: 10px;
    }
}
.buttonBuild{
    padding-left: 15px;
    width: 50%; 
    margin-top: -10%;  
}
.buttonssss{
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 20px;
    img{
        width: 8%;
    }
}
.buttonOk{  
    margin: 20px;
    width: 100%;
    padding: 10px;
    border-radius: 20px;
    background: #edd77c;
    font-size: 18px;
    font-weight: bold;
    border: 600;
    border: none;
}

.buttonOkv{
    margin: 20px;
    width: 250px;
    padding: 10px;
    border-radius: 20px;
    background: #edd77c;
    font-size: 18px;
    font-weight: bold;
    border: 600;
    border: none;
}

.MuiBackdrop-root{
    z-index: 0 !important;
    position:  relative !important;
}
.buttonBack{
    margin: 20px;
    width: 100%;
    padding: 10px;
    border-radius: 20px;
    border: none;
    font-size: 18px;
    font-weight: 600;
}
.withoutBorder{
    border-color: transparent !important;
}
.text-center{
    a{
        text-decoration: none!important;
    }
}

.fifity{
    width: 50%;
}
.buttonForget{
    
    
    text-align: center;
    display: block;
  color: white;
  margin: auto 0;
  padding-top: 20px !important;
    padding-bottom: 20px !important;
    font-size: 12px;
    font-weight: 600;
   a{
    text-decoration: none !important;
   }
  
}

.right{
    text-align: right;
}
.flexMapsInfo{        
    width: 100%;
    margin-bottom: 30px;
   
    img{
        width: 100% ;
        height: 400px;
        object-fit: cover;
    }    
    p{
        margin-top: 20px;
    }
    table{
        width: 100%;
    }
}
.bold{
    font-weight: bold;
    h6{
        font-weight: 900;
        color:#777777;
    }
    
}

.officeCampo{
    h6{
       font-weight: 400;
       color:#777777;
       min-height: 50px;
    }
}

.subtitulo-descripcion{
    table tr{
        border-bottom: 2px solid #F1C677;        

    }
}
.lines{
    td{
        
        padding-top: 20px;
    }
}

.pleca{    
    text-align: right;   
    img{
        margin-top: -8%;
        height: 5px !important;
        width: 148px !important;

    object-fit: contain !important;
    }
}
.plecaTitle2{
    text-align: left;
    img{
        margin-top: -5%;
        margin-left: 0%;
        height: 5px !important;  
    }  
}
.plecaTitleBuild{
    text-align: left;   
    img{
        margin-top: 20%;
        margin-left: -170%;
        height: 5px !important;  
    }  
}
.plecaTitle{    
    text-align: left;   
    img{
        margin-top: 20%;
        margin-left: -200%;
        height: 5px !important;  
    }
}
.plecaGallery{       
    h1{
        padding:5px;
        text-align: left !important;
        margin-left: 1%;
        margin-top:2%;
        text-transform: uppercase;
    }   
    img{
        margin-top: -8%;
        height: 5px !important;  
        width: 35% !important;        
    }
}
.imgGallery{
    border-radius: 20px 20px 0px 0px !important;
}
.buttonsGallery{
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    img{
        width: 10% !important;
        height: 10% !important;
        // background: #c9d753;
    }
    button{
        width: 35%;
        height: 20%;
        border:none;
        background: linear-gradient(to right, #93b343 0%, #c9d753 100%);
        border-radius: 5px;
        padding:5px;
        font-weight: bold;
    }
}
.margin{
    padding: 5px !important;
}
.Gallery{
    border: #93b343 solid 1px;    
    padding: 10px;
    border-radius: 0px 0px 20px 20px !important;
}
.space{
    height: 40px;   
}
.separator{
    height: 0%;
    position: absolute;
    margin-top: 22%;
    width: 100% !important;
    margin-left: 0%;
}
.carousel-control-prev,.carousel-control-next {
    margin-top: -15%;
}
.buttonDegra{
    background: linear-gradient(to right, #93b343 0%, #c9d753 100%);
    font-weight: bold;
    height: 40px;
    width: 150px;
    border: none;
    border-radius: 20px;
}

.upper{
    text-transform: uppercase;
}
.login{
    margin-top: 50px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    input{
        padding-left: 10px;
        margin: 2px;
    }
    label{
        display: none;
    }
}
.buttonLoginNav{
    display: flex;
    flex-direction: column;
    button{
        
        // height: 50px;
        margin: 5px;
        width: 60px;
        background: transparent;
        font-size: 12px;
        font-weight: bold;
        border: none;
    }
}
.buttonLogin{
    display: flex;
    flex-direction: column;
    button{
        border-radius: 30px;
        height: 50px;
        margin: 5px;
        width: 250px;
        background: linear-gradient(transparent  , #93b343 0%, #c9d753 100%);
        font-size: 24px;
        font-weight: bold;
        border: none;
    }
    
}
    @media only screen and (max-width: 1600px){ 
    .center{    
        width: 100%;
    }      
    }

    @media only screen and (max-width: 1700px){ 
        .center{    
            width: 90%;
        }  
        }

    @media only screen and (max-width: 1800px){ 
        .center{    
            width: 85%;
        }  
        .navbar-brand{
            .img{
                width: 70%;        
            }
        }
        .flex{
            width: 150%;
           
        }  
        .infoGral{     
            // padding-left: 7%;    
            // position: relative;  
            // width: 95%;
        }
        }    

@media only screen and (max-width: 1316px){  
    .nav-item{        
            width: max-content;    
    }     

}
@media only screen and (min-width: 1370px) and (max-width: 1399px){
    .infoBuild{
        width: 55.5% !important ;
    }
}
@media only screen and (min-width: 1400px){
    .infoGral{
        margin-left: 80px;
        width: 1200px;
    }
    .infoBuild{
        width: 63%;
    }
    .buttonOk{
        width: 85%;
    }
    .buttonBack{
        width: 85%;
    }
}
@media only screen and (max-width: 1260px){    
    .flex{
        width: 150%;
        margin-left: 0px;
    }
    .content, body {        
        width: 100%;
    }
    .navbar-brand{
       margin-right: 60px;
        .img{
            width: 250px;        
        }
    }  
    .center{    
        width: 90%;
    }    

    .white{
       font-size: 32px;
    }
}    

//trabajar aquí
@media only screen and (min-width: 900px) and (max-width: 1259px) {
    .navbar-brand{
        margin-right: 80px; 
        width: 100%;              
     }  
     .flex{
        width: 100%;
        margin-left: -50px;
       
    }
     .iconsNavbar{
         margin-left: 80px;
         max-width: 40%;
     }
     figcaption{
        width: 150%;
        margin-left: -20px;
    }  
    .collapse{
        width: 30%;
    }
    .center{    
        width: 80%;
    } 
}


@media only screen and (max-width: 900px){  
    .mobilePadding{
        padding: 0!important;  
    }
    .thumbnails{  
        
        display: none !important;     
    }
    .fifity{
        width: 100%;
    }

    // .colorHead{
    //     font-size: calc(1.375rem + 1.5vw);;
    // }

    .center{    
        width: 100%;
    }  
    .navbar-brand{
        .img{
            width: 100%;        
        }
    }
    .flex{
        width: 100%;
        text-align: right;
        margin-left: 30px ;
        margin-top: 10px;
    }
    
    h2
    .iconHome{      
        img{
            width: 100px;
        }
    }
  footer{
      p{
          font-size: 10px;
      }
  }
  .home{
    margin-top: 20%;
    text-align: center;   
    min-height: 200px;
}
.iconHome{
    img{
        width: 80%;
    }
    h2{
        margin-top: -10px;
        font-size: 15px;
    }
}
.flex{
    margin-left: -5px;
}
.underline{    
    background-image: linear-gradient(transparent 15px , #93b343 50%, #c9d753 100%);    
}
}

@media only screen and (max-width: 600px){ 
.white{
    font-size: 18px;
}

}

@media only screen and (max-width: 1399px){
    .thumbnails{  
        
        img{
            // height: 100%;  
            width: 100% !important;
            max-width: 210px !important;    
        }        
    }
    .carousel-image{
        width: 100% !important;
    }
    .carouselGral{
        a.next{
            margin-right: 13% !important;
     
        }
    }
}


@media only screen and (max-width: 372px){    
    .navbar-brand{
        .img{
            width: 120%;        
        }
    }
    .flex{
        width: 100%;
        text-align: right;
        margin-left: 25px ;
        margin-top: 20px;
        
    }
    .white{
        font-size: 14px;
    }
    // .colorHead{
    //     font-size: 14px;
    // }
    .iconHome{
        img{
            width: 80%;
        }
        h2{
            margin-top: -10px;
            font-size: 12px;
        }
    }
    .underline{    
        background-image: linear-gradient(transparent 12px , #93b343 50%, #c9d753 100%);    
    }
    .home{
        margin-top: 20%;
        text-align: center;   
        min-height: 200px;
    }
 
}

@media only screen and (max-width: 339px){    
    
    .flex{
        width: 100%;
        text-align: right;
        margin-left: 15px ;
        margin-top: 20px;        
    }
   .navbar-brand  {
    .img{
        width: 100%;
    }
    
}
    .home{
        margin-top: 20%;
        text-align: center;   
        min-height: 200px;
    }
 
}